var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overflow-scroll"},[_c('div',{staticStyle:{"min-width":"1260px"}},[_c('a-card',{attrs:{"bordered":false,"tabList":_vm.tabListNoTitle,"activeTabKey":_vm.noTitleKey},on:{"tabChange":key => _vm.onTabChange(key, 'noTitleKey')}},[(_vm.noTitleKey === 'baseDetail')?_c('a-card',{staticStyle:{"margin-left":"-24px","margin-top":"-24px"},attrs:{"bordered":false}},[_c('DescriptionList',{attrs:{"title":"客户信息","size":"large"}},[_c('div',{staticClass:"table-operator table-top24"},[_c('a-form',{attrs:{"layout":"inline","form":_vm.form}},[_c('a-row',{staticStyle:{"line-height":"4"},attrs:{"gutter":5}},[_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"客户编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'code',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入!'
                            }
                          ],
                          initialValue: _vm.detail.code
                        }
                      ]),expression:"[\n                        'code',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: '请输入!'\n                            }\n                          ],\n                          initialValue: detail.code\n                        }\n                      ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":16,"sm":24}},[_c('a-form-item',{attrs:{"label":"客户名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'tenantName',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入!'
                            }
                          ],
                          initialValue: _vm.detail.name
                        }
                      ]),expression:"[\n                        'tenantName',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: '请输入!'\n                            }\n                          ],\n                          initialValue: detail.name\n                        }\n                      ]"}],staticStyle:{"width":"607px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"客户类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'type',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请选择!'
                            }
                          ],
                          initialValue: this.showMessage(_vm.detail.type)
                        }
                      ]),expression:"[\n                        'type',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: '请选择!'\n                            }\n                          ],\n                          initialValue: this.showMessage(detail.type)\n                        }\n                      ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.tenantType),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"客户等级"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'level',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请选择!'
                            }
                          ],
                          initialValue: this.showMessage(_vm.detail.level)
                        }
                      ]),expression:"[\n                        'level',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: '请选择!'\n                            }\n                          ],\n                          initialValue: this.showMessage(detail.level)\n                        }\n                      ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.levelList),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"经营品类"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'cuisineType',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请选择!'
                            }
                          ],
                          initialValue: this.showMessage(_vm.detail.cuisineType)
                        }
                      ]),expression:"[\n                        'cuisineType',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: '请选择!'\n                            }\n                          ],\n                          initialValue: this.showMessage(detail.cuisineType)\n                        }\n                      ]"}],staticStyle:{"width":"974px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.cuisineTypeList),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)],1)],1)],1)]),_c('Divider'),_c('DescriptionList',{attrs:{"title":"联系方式","size":"large"}},[_c('div',{staticClass:"table-operator table-top24"},[_c('a-form',{attrs:{"layout":"inline","form":_vm.form}},[_c('a-row',{staticStyle:{"line-height":"4"},attrs:{"gutter":5}},[_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"账户名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'bankName',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入!'
                            }
                          ],
                          initialValue: _vm.detail.bankName
                        }
                      ]),expression:"[\n                        'bankName',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: '请输入!'\n                            }\n                          ],\n                          initialValue: detail.bankName\n                        }\n                      ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"银行账户"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'bankAccount',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入!'
                            }
                          ],
                          initialValue: _vm.detail.bankAccount
                        }
                      ]),expression:"[\n                        'bankAccount',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: '请输入!'\n                            }\n                          ],\n                          initialValue: detail.bankAccount\n                        }\n                      ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"开户银行"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'bankBranch',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入!'
                            }
                          ],
                          initialValue: _vm.detail.bankBranch
                        }
                      ]),expression:"[\n                        'bankBranch',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: '请输入!'\n                            }\n                          ],\n                          initialValue: detail.bankBranch\n                        }\n                      ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"客户主体"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'tenantEntity',
                        {
                          rules: [
                            {
                              required: true,
                              message: '请输入!'
                            }
                          ],
                          initialValue: _vm.detail.tenantEntity
                        }
                      ]),expression:"[\n                        'tenantEntity',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: '请输入!'\n                            }\n                          ],\n                          initialValue: detail.tenantEntity\n                        }\n                      ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入"}})],1)],1)],1)],1)],1)])],1):_vm._e()],1),_c('div',{staticStyle:{"margin-top":"8px"}},[_c('a-card',{attrs:{"bordered":false}},[_c('div',{staticClass:"steps-action"},[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v(" 取消 ")]),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary"},on:{"click":_vm.conserve}},[_vm._v(" 保存 ")])],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }