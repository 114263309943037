<template>
  <div class="overflow-scroll">
    <div style="min-width: 1260px">
      <a-card
        :bordered="false"
        :tabList="tabListNoTitle"
        :activeTabKey="noTitleKey"
        @tabChange="key => onTabChange(key, 'noTitleKey')"
      >
        <a-card
          v-if="noTitleKey === 'baseDetail'"
          :bordered="false"
          style="margin-left: -24px;margin-top: -24px"
        >
          <DescriptionList title="客户信息" size="large">
            <div class="table-operator table-top24">
              <a-form layout="inline" :form="form">
                <a-row :gutter="5" style="line-height: 4">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="客户编号">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'code',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入!'
                              }
                            ],
                            initialValue: detail.code
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="16" :sm="24">
                    <a-form-item label="客户名称">
                      <a-input
                        style="width: 607px"
                        v-decorator="[
                          'tenantName',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入!'
                              }
                            ],
                            initialValue: detail.name
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <!--                  <a-col :md="8" :sm="24">-->
                  <!--                    <a-form-item label="客户状态">-->
                  <!--                      <a-select-->
                  <!--                        style="width: 240px"-->
                  <!--                        v-decorator="[-->
                  <!--                          'status',-->
                  <!--                          {-->
                  <!--                            rules: [-->
                  <!--                              {-->
                  <!--                                required: true,-->
                  <!--                                message: '请选择!'-->
                  <!--                              }-->
                  <!--                            ],-->
                  <!--                            initialValue: this.showMessage(detail.tenantStatus)-->
                  <!--                          }-->
                  <!--                        ]"-->
                  <!--                        placeholder="请选择"-->
                  <!--                      >-->
                  <!--                        <a-select-option-->
                  <!--                          v-for="item in tenantStatus"-->
                  <!--                          :key="item.id"-->
                  <!--                        >-->
                  <!--                          {{ item.name }}-->
                  <!--                        </a-select-option>-->
                  <!--                      </a-select>-->
                  <!--                    </a-form-item>-->
                  <!--                  </a-col>-->
                  <a-col :md="8" :sm="24">
                    <a-form-item label="客户类型">
                      <a-select
                        style="width: 240px"
                        v-decorator="[
                          'type',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择!'
                              }
                            ],
                            initialValue: this.showMessage(detail.type)
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="item in tenantType"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="客户等级">
                      <a-select
                        style="width: 240px"
                        v-decorator="[
                          'level',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择!'
                              }
                            ],
                            initialValue: this.showMessage(detail.level)
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="item in levelList"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :sm="24">
                    <a-form-item label="经营品类">
                      <a-select
                        style="width: 974px"
                        v-decorator="[
                          'cuisineType',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择!'
                              }
                            ],
                            initialValue: this.showMessage(detail.cuisineType)
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="item in cuisineTypeList"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </div>
          </DescriptionList>
          <Divider />
          <DescriptionList title="联系方式" size="large">
            <div class="table-operator table-top24">
              <a-form layout="inline" :form="form">
                <a-row :gutter="5" style="line-height: 4">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="账户名称">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'bankName',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入!'
                              }
                            ],
                            initialValue: detail.bankName
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="银行账户">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'bankAccount',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入!'
                              }
                            ],
                            initialValue: detail.bankAccount
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="开户银行">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'bankBranch',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入!'
                              }
                            ],
                            initialValue: detail.bankBranch
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="客户主体">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'tenantEntity',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入!'
                              }
                            ],
                            initialValue: detail.tenantEntity
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </div>
          </DescriptionList>
        </a-card>
      </a-card>
      <div style="margin-top: 8px">
        <a-card :bordered="false">
          <div class="steps-action">
            <a-button @click="cancel">
              取消
            </a-button>
            <a-button type="primary" style="margin-left: 8px" @click="conserve">
              保存
            </a-button>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import DescriptionList from "@/components/DescriptionList";
import { Divider } from "ant-design-vue";
const { Description } = DescriptionList;
  
import { modal } from "ant-design-vue";
import Bus from "@/bus";
export default {
  name: "roleListEdit",
  components: { DescriptionList, Divider },
  data() {
    return {
      roleListEditId: this.$route.query.roleListEditId,
      formLayout: "inline",
      tabListNoTitle: [
        {
          key: "baseDetail",
          tab: "基本信息"
        }
      ],
      detail: "",
      noTitleKey: "baseDetail",
      form: this.$form.createForm(this),
      //客户状态
      tenantStatus: [
        {
          id: 1,
          name: "预定中"
        },
        {
          id: 2,
          name: "已预订"
        },
        {
          id: 3,
          name: "签约中"
        },
        {
          id: 4,
          name: "已签约"
        },
        {
          id: 5,
          name: "改造中"
        },
        {
          id: 6,
          name: "待入驻"
        },
        {
          id: 7,
          name: "已入驻"
        },
        {
          id: 8,
          name: "申请退租中"
        },
        {
          id: 9,
          name: "退租中"
        },
        {
          id: 10,
          name: "已退租"
        },
        {
          id: 11,
          name: "已离场"
        }
      ],
      //客户等级
      levelList: [
        {
          id: 1,
          name: "中小客户"
        },
        {
          id: 2,
          name: "战略客户"
        }
      ],
      //经营品类
      cuisineTypeList: [
        { id: 1, name: "粤菜" },
        { id: 2, name: "川湘菜" },
        { id: 3, name: "江浙菜" },
        { id: 4, name: "快餐便当" },
        { id: 5, name: "简餐沙拉" },
        { id: 6, name: "米粉面馆" },
        { id: 7, name: "饺子馄饨" },
        { id: 8, name: "包子粥店" },
        { id: 9, name: "生煎锅贴" },
        { id: 10, name: "小吃烧烤" },
        { id: 11, name: "香锅干锅" },
        { id: 12, name: "海鲜龙虾" },
        { id: 13, name: "砂锅汤类" },
        { id: 14, name: "火锅" },
        { id: 15, name: "西餐" },
        { id: 16, name: "东南亚菜" },
        { id: 17, name: "日韩菜" },
        { id: 18, name: "意面披萨" },
        { id: 19, name: "汉堡薯条" },
        { id: 20, name: "其他地方菜系" },
        { id: 21, name: "甜品饮品" },
        { id: 22, name: "果蔬生鲜" },
        { id: 23, name: "鲜花绿植" },
        { id: 24, name: "医药健康" },
        { id: 25, name: "商品超市" },
        { id: 26, name: "服装鞋帽" },
        { id: 27, name: "美妆" }
      ],
      //客户类型
      tenantType: [
        {
          id: 1,
          name: "品牌直营"
        },
        {
          id: 2,
          name: "连锁加盟"
        },
        {
          id: 3,
          name: "个体客户"
        }
      ]
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/role/RoleList"
      });
    });
  },
  methods: {
    onTabChange(key, type) {
      console.log(key, type);
      this[type] = key;
    },
    init() {
      axios({
        url:   this.$baseUrl + "/tenant/query",
        method: "GET",
        params: {
          id: this.roleListEditId
        }
      }).then(res => {
        this.detail = res.data.obj;
      });
    },
    conserve() {
      let that = this;
      const {
        form: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          modal.confirm({
            title: "该页面信息已编辑，是否需要保存?",
            onOk() {
              that.tenantModify(values);
              that.$router.push({ path: "/role/RoleList" });
              return new Promise((resolve, reject) => {
                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
              }).catch(() => console.log("Oops errors!"));
            },
            onCancel() {}
          });
        }
      });
    },
    tenantModify(values) {
      axios({
        url:   this.$baseUrl + "/tenant/modify",
        method: "POST",
        withCredentials: true,
        data: {
          ...values,
          id: this.roleListEditId
        }
      }).then(res => {
        this.$router.push({ path: "/role/RoleList" });
      });
    },
    cancel() {
      this.$router.push({ path: "/role/RoleList" });
    },
    showMessage(val) {
      return val ? val : undefined;
    }
  }
};
</script>

<style scoped>
.steps-action {
  float: right;
}
.table-top24 {
  margin-bottom: 16px;
  margin-left: 8px;
  margin-top: 24px;
}
</style>
